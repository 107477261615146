import React, { useContext } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CustomAuth from "../../components/custom-auth"
import { UserContext } from "../../provider/usercontextprovider"
import TenantConfiguration from "../../components/tenant-configuration";
import { UserTenantProvider } from "../../provider/usertenantprovider"

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isManager = userContext.username && userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('TenantAdmin');

  return (
        <CustomAuth>
        <SEO title="Tenant Configuration" />
          <UserTenantProvider>
            {isManager ? <TenantConfiguration/> : <p>User is not a Tenant Administrator</p>}
          </UserTenantProvider>
        </CustomAuth>
  )

}
export default ProtectedPage