import React, { useContext, useEffect, useState, useCallback } from "react"
import { API, graphqlOperation } from "aws-amplify"
import * as styles from "./loadmask.module.css"
import * as liststyles from "./list.module.css"
import * as RemoveButton from "./svgbutton/removebutton"
import Select from 'react-select'
import * as inputStyles from './search-input.module.css'
import { UserTenantContext } from "../provider/usertenantprovider"
import { LoadMaskContext } from "../provider/loadmaskprovider"
import { debounce } from 'lodash';
import { Notification, NotificationType } from "./notification";
import * as badgeStyles from "../styles/badge.module.css";
import * as formElementStyles from "../styles/form-elements.module.css";

const updateTenantMutation = /* GraphQL */`
  mutation updateTenant(
    $input:UpdateTenantInput!,
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input:$input, condition: $condition){
      name
      description
      prettyName
      resultMetricEmailList
      resultMetricEmailTime
      questionnaireValidityTimeInSeconds
    }
  }`

const widthStyles = {
  container: base => ({
    ...base,
    width: '50%'
  })
};

const widthStylesValidity = {
  container: base => ({
    ...base,
    width: '20%'
  })
};

const TenantConfiguration = props => {
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [tenant, setTenant] = useState(null)
  const [tenantList, setTenantList] = useContext(UserTenantContext)
  const [validityTimeInMinutes, setValidityTimeInMinutes] = useState(null)
  const [message, setMessage] = useState({
    show: false
  });

  const options = tenantList.filter(t=>t.tenantRole.includes("Admin")).map(tenant => {
    return {
      value: tenant.tenantObj.name,
      label: tenant.tenantObj.prettyName,
      resultMetricEmailTime: tenant.tenantObj.resultMetricEmailTime,
      resultMetricEmailList: tenant.tenantObj.resultMetricEmailList,
      questionnaireValidityTimeInSeconds: tenant.tenantObj.questionnaireValidityTimeInSeconds
    }
  })

  const onTenantChange = e => {
    setTenant(e)
    setValidityTimeInMinutes(Math.floor(e.questionnaireValidityTimeInSeconds/60))
    console.log(e)
  }

  const onTimeChange = option => {
    let newTenant = {
      ...tenant,
      resultMetricEmailTime: getUTCHours(option.value)
    };
    console.log("New Tenant?")
    console.log(newTenant)
    saveTenant(newTenant)
  }

  let onValidityMinutesChange = (validityTimeInMinutesEle) => {
    let validityTimeInMinutesLocal = validityTimeInMinutesEle.target.value
    console.log(validityTimeInMinutesLocal)
    setValidityTimeInMinutes(validityTimeInMinutesLocal)
    if( validityTimeInMinutesLocal == '' || validityTimeInMinutesLocal <= 0 ){
      setMessage({
        show: true,
        msg: 'Validity time must be greater then 0 minutes',
        messageType: NotificationType.FAIL
      });
      return;
    // this is the max we can store in minutes as 2147483647 is integer max for appsync
    }else if ( validityTimeInMinutesLocal > 35791394 ) {
      setMessage({
        show: true,
        msg: 'Validity time must be less then 68 years',
        messageType: NotificationType.FAIL
      });
      return;
    }else{
      setMessage({
        show: false
      });
    }

    let newTimeInSeconds = validityTimeInMinutesLocal * 60
    let newTenant = {
      ...tenant,
      questionnaireValidityTimeInSeconds: newTimeInSeconds 
    };
    console.log(newTenant)
    debounceSaveTenant(newTenant)
  }

  const debounceSaveTenant = useCallback( debounce(value => saveTenant(value), 500), [] );

  const saveTenant = async (newTenant) => {
    setIsLoading(true)
    let result = await API.graphql(graphqlOperation(updateTenantMutation, {
      input: {
        name: newTenant.value,
        resultMetricEmailList: newTenant.resultMetricEmailList,
        resultMetricEmailTime: newTenant.resultMetricEmailTime,
        questionnaireValidityTimeInSeconds: newTenant.questionnaireValidityTimeInSeconds
      }
    }));
    newTenant.resultMetricEmailList = result.data.updateTenant.resultMetricEmailList
    newTenant.resultMetricEmailTime = result.data.updateTenant.resultMetricEmailTime
    newTenant.questionnaireValidityTimeInSeconds = result.data.updateTenant.questionnaireValidityTimeInSeconds
    onTenantChange(newTenant)
    setIsLoading(false)
  }

  const addIfEnter = event => {
    if (event.key === "Enter" && !event.target.validity.typeMismatch
      && tenant.resultMetricEmailList.indexOf(event.target.value) < 0) {
      let emailList = tenant.resultMetricEmailList;
      emailList.push(event.target.value);
      let newTenant = {
        ...tenant,
        resultMetricEmailList: emailList
      };
      saveTenant(newTenant)
      event.target.value = "";
    }
  }

  const removeEmail = (removeEmailItem) => event => {
    let emailList = tenant.resultMetricEmailList
    emailList.splice(emailList.indexOf(removeEmailItem), 1)
    let newTenant = {
      ...tenant,
      resultMetricEmailList: emailList
    };
    saveTenant(newTenant)
  }

  const getUTCHours = (hour) => {
    let d = new Date()
    d.setHours(parseInt(hour))
    return d.getUTCHours().toString()
  }

  const getLocalHours = (hour) => {
    let cur = new Date()
    let d = new Date(Date.UTC(cur.getUTCFullYear(), cur.getUTCMonth(), cur.getUTCDate(), parseInt(hour)))
    return d.getHours().toString()
  }

  const amTimes = [...Array(12).keys()].map(num => {return {label: `${num === 0 ? 12 : num}:00 AM`, value: num.toString()}});
  const pmTimes = [...Array(12).keys()].map(num => {return {label: `${num === 0 ? 12 : num}:00 PM`, value: (num+12).toString()}})

  const timeOptions = amTimes.concat(pmTimes)
  return (
    <>
        <h1>Tenant Configurations</h1>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "1em" }}>
            <Select isSearchable={false} styles={widthStyles} options={options} onChange={onTenantChange} />
        </div>
        {message.show && 
            <Notification messageType={message.messageType}>
                <p>{message.msg}</p>
            </Notification> }
        {tenant && (
            <div style={{marginTop:'2rem'}}>
                <h2 style={{ fontSize: "1.125rem", lineHeight: "1.5rem", fontWeight: "medium", color: "#111827", marginBottom: "0" }}>Daily/Monthly Email Rollup Configuration</h2>
                <p style={{ marginTop: "0.25rem", fontSize: "1rem", color: "#6B7280" }}>Select the time at which the email will be sent and the recipients</p>
                <label htmlFor="tenantEmailTime" style={{ display: "block", fontSize: "1rem", fontWeight: "medium", color: "#374151" }}>Hour Email is Sent</label>
                <Select name="tenantEmailTime" value={timeOptions.find(x=>x.value===(getLocalHours(tenant.resultMetricEmailTime)))} styles={widthStyles} options={timeOptions} onChange={onTimeChange} />
                <label style={{ display: "block", marginTop: "1rem" }}>Recipients for Rollup Emails:</label>
                <div style={{ marginTop: "0.5rem" }}>
                    <div className={formElementStyles.multiSelectWrapper}>
                        {tenant.resultMetricEmailList.map((emailAddress, index) => (
                            <div style={{ marginRight: "0.375rem", padding: "0" }} key={index}>
                                <span className={`${badgeStyles.badge} ${badgeStyles.badgeWithButton}`}>
                                    {emailAddress}
                                    <button type="button" className={badgeStyles.removeButton} onClick={removeEmail(emailAddress)}>
                                        <svg style={{ height: "1rem", width: "1rem" }} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </span>
                            </div>
                        ))}
                        <input type="text" className={formElementStyles.multiSelectInput} onKeyDown={addIfEnter} />
                    </div>
                </div>
                <h2 style={{ fontSize: "1.125rem", lineHeight: "1.5rem", fontWeight: "medium", color: "#111827", marginTop: "2rem", marginBottom: "1rem" }}>Questionnaire Validity Time</h2>
                <label style={{display: "block"}} for='validityTimeInMinutesInput'>Validity Time for a Questionnaire (Minutes):</label>
                <input style={{display: "block"}} className={inputStyles.inputcss} name="validityTimeInMinutesInput" type="number" onChange={onValidityMinutesChange} value={validityTimeInMinutes} />
            </div>
        )}
    </>
  )
}

export default TenantConfiguration
